import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetMaquinas,
  urlDeleteMaquinas,
} from "../../../../api/URLs/mantenimiento";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMULARIO
import FormMaquinas from "../forms/FormMaquinas";
import FormLinkChecklist from "../forms/FormLinkChecklist";
import InformeModal from "./InformeModal";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import PlainModal from "../../../../components/Modals/ModalPlain";
//--Table
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../components/Tools/Basics/NoWrapContainer";
//EXCEL
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const TableMaquinas = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //useNavigate
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [maquinas, setMaquinas] = useState([]);
  const [selectedMaquina, setSelectedMaquina] = useState(null);
  const [modalState, setModalState] = useState(null);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getMaquinas = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetMaquinas);
      const maquinas = api_response.data;

      // Aquí obtenemos los checklists para cada máquina
      const maquinasConChecklists = await Promise.all(
        maquinas.map(async (machine) => {
          const checklistResponse = await axiosWithAuth.get(
            `/api/resultados/checklist?id_maquina=${machine.id_maquina}`
          );
          return {
            ...machine, // Mantenemos las propiedades originales de la máquina
            checklist: checklistResponse.data, // Agregamos el checklist a la máquina
          };
        })
      );

      setMaquinas(maquinasConChecklists); // Guardamos las máquinas con sus checklists
    } catch (error) {
      const auxError = error.response?.data?.msj || "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //DELETE
  const handleDelete = async (id_maquina) => {
    const merged = { id: id_maquina };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.delete(urlDeleteMaquinas, {
        data: merged,
      });
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = (maquina) => {
    if (
      window.confirm(
        `Confirmar eliminación de la maquina: ${maquina.id_interno}?`
      )
    ) {
      handleDelete(maquina.id_maquina);
    }
  };
  //HANDLES
  const handleInforme = (maquina) => {
    setSelectedMaquina(maquina);
    setModalState("informe");
    setShowModalPlain(true);
  };
  const handleForm = (maquina) => {
    if (maquina) {
      setSelectedMaquina(maquina);
    } else {
      setSelectedMaquina(null);
    }
    setModalState(null);
    setShowModalPlain(true);
  };
  const handleChecklist = (maquina) => {
    setSelectedMaquina(maquina);
    setModalState("linkChkl");
    setShowModalPlain(true);
  };
  //BOTONES
  const actions = (maquina) => {
    return (
      <NoWrapContainer>
        <CustomBtn
          btnType={"info btn-sm py-0 me-1"}
          text={"Informe"}
          onClick={() => handleInforme(maquina)}
        />
        <CustomBtn type={"edit_table"} onClick={() => handleForm(maquina)} />
        <CustomBtn
          type={"delete_table"}
          onClick={() => confirmDelete(maquina)}
        />
        <CustomBtn
          type={"link_table"}
          text={"Checklists"}
          onClick={() =>
            navigate(`/maquina/checklists/${maquina.id_maquina}`, {
              state: { maquina },
            })
          }
        />
      </NoWrapContainer>
    );
  };
  const checklistButton = (maquina) => {
    return maquina.id_checklist ? (
      <NoWrapContainer>
        <span>{maquina.nombre_checklist}</span>
        <CustomBtn
          type={"link_table"}
          icon={"bi bi-pencil-square ms-1"}
          onClick={() => handleChecklist(maquina)}
        />
      </NoWrapContainer>
    ) : (
      <CustomBtn
        type={"link_table"}
        text={"Vincular"}
        onClick={() => handleChecklist(maquina)}
      />
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    date.setHours(date.getHours() + 3);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan en 0
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  //FUNCION PARA EXPORTAR A EXCEL
  const exportToExcel = async () => {
    const wb = XLSX.utils.book_new();

    const fetchDetails = async (checklistId) => {
      try {
        const api_response = await axiosWithAuth.get(
          `/api/resultado/det/checklist?id_resultado_chk=${checklistId}`
        );
        return api_response.data;
      } catch (error) {
        const auxError = error.response?.data?.msj || "Error del servidor";
        handleError(auxError);
        return null; // Maneja el error adecuadamente
      }
    };

    // Array para almacenar promesas de la obtención de detalles
    const fetchDetailsPromises = maquinas.map(async (machine) => {
      let { checklist } = machine;

      if (!checklist || checklist.length === 0) {
        console.warn(
          `La máquina ${machine.marca} ${machine.id_interno} no tiene checklists.`
        );
        return null;
      }

      // Ordenar checklists por fecha en orden descendente
      checklist.sort(
        (a, b) => new Date(b.fec_restulado_chk) - new Date(a.fec_restulado_chk)
      );

      const closedChecklists = checklist.filter(
        (chk) => chk.chk_cerrada === true
      );

      // Obtener el checklist más reciente
      const lastChecklist = closedChecklists[0];

      // Obtener detalles del checklist
      const chkDetails = await fetchDetails(lastChecklist.id_resultado_chk);

      // Crear datos para la hoja de trabajo
      const headerData = [
        [
          "ID Maquina",
          "Marca",
          "Modelo",
          "ID Checklist",
          "Fecha",
          "Horas Uso",
          "Maquinista",
          "Otras Observaciones",
          "Cerrada",
        ],
        [
          lastChecklist.id_maquina, // ID de la interno
          lastChecklist.maq_marca, // Marca de la máquina
          lastChecklist.maq_modelo, // Modelo de la máquina
          lastChecklist.id_resultado_chk, // ID del checklist
          formatDate(lastChecklist.fec_restulado_chk), // Fecha del resultado
          lastChecklist.horas_uso_fin, // Horas de uso
          lastChecklist.maquinista, // Nombre del maquinista
          lastChecklist.otras_obs === "" ? "N/A" : lastChecklist.otras_obs, // Otras observaciones
          lastChecklist.chk_cerrada ? "Sí" : "No", // Estado del checklist (cerrado o no)
        ],
        ["", "", "", "", "", "", "", "", ""], // Espacio en blanco
        ["Item", "Valor", "Observaciones"], // Encabezado de la lista de chequeo
      ];
      const checklistdata = chkDetails.map((detail) => [
        detail.nombre,
        detail.valor === "1" ? "Sí" : "No",
        detail.obs,
      ]);

      const data = [...headerData, ...checklistdata];

      // Convertir los datos a una hoja de trabajo de Excel
      const ws = XLSX.utils.aoa_to_sheet(data);

      ws["!cols"] = [
        { wch: 20 }, // ID Máquina
        { wch: 20 }, // Marca
        { wch: 20 }, // Modelo
        { wch: 20 }, // ID Checklist
        { wch: 20 }, // Fecha
        { wch: 20 }, // Horas Uso
        { wch: 20 }, // Maquinista
        { wch: 20 }, // Observaciones
        { wch: 20 }, // Cerrada
      ];

      // Agregar la hoja al libro de trabajo
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${machine.id_interno}, ${machine.marca} ${machine.modelo}`
      );
    });

    // Esperar que todas las promesas se resuelvan
    await Promise.all(fetchDetailsPromises);

    if (wb.SheetNames.length === 0) {
      console.error("No hay datos para exportar a Excel.");
      return;
    }

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const buffer = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < wbout.length; i++) {
      view[i] = wbout.charCodeAt(i) & 0xff;
    }

    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `Checklists Maquinas ${new Date().toLocaleDateString()}.xlsx`
    );
  };

  const buttons = [
    {
      type: "plus_table_header",
      text: " Maquina",
      click: handleForm,
      noData: true,
    },
    {
      type: "plus_table_header",
      text: " Exportar XLSX",
      click: exportToExcel,
      noData: true,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id_maquina" },
    { header: "Id Int.", field: "id_interno" },
    { header: "Tipo", field: "tipo", sortable: true },
    { header: "Marca", field: "marca" },
    { header: "Modelo", field: "modelo" },
    {
      header: "Checklist.",
      field: "id_checklist",
      body: checklistButton,
      sortable: true,
    },
    { body: actions },
  ];
  useEffect(() => {
    getMaquinas();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={maquinas}
        title={"Maquinas"}
        columns={columns}
        buttons={buttons}
        loading={loading}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal
          show={true}
          handleClose={handleCloseModalPlain}
          size={modalState === "linkChkl" ? "lg" : null}
        >
          {modalState === "linkChkl" ? (
            <FormLinkChecklist
              maquina={selectedMaquina}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          ) : modalState === "informe" ? (
            <InformeModal maq={selectedMaquina} />
          ) : (
            <FormMaquinas
              maquina={selectedMaquina}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          )}
        </PlainModal>
      ) : null}
    </div>
  );
};

export default TableMaquinas;
