import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
//PAGES
//--Basisc
import SignupPage from "./pages/Login/SignupPage";
import NotFoundPage from "./pages/Login/NotFoundPage";
import HomePage from "./pages/Login/HomePage";
//--Entidades
import Vehiculos from "./pages/Camiones/Vehiculos";
import Choferes from "./pages/Camiones/Choferes";
import Cubiertas from "./pages/Camiones/Cubiertas";
import Operativos from "./pages/Camiones/Operativos";
import VehiculosPersonal from "./pages/Personal/VehiculosPersonal";
import CubiertasPersonal from "./pages/Personal/CubiertasPersonal";
//import PageOperativos from "./pages/Viajes/components/extra/pages/PageOperativos";
//import ViajeDetalles from "./pages/Viajes/details";
import Combustible from "./pages/Camiones/EntidadesSecundarias/Combustible";
import OperativosViajes from "./pages/Camiones/Operativos/details";
import ChoferesDetalles from "./pages/Camiones/Choferes/details";
import VehiculosDetalles from "./pages/Camiones/Vehiculos/components/details/details";
import VehiculosPersonalDetalles from "./pages/Personal/VehiculosPersonal/details";
import OperacionesEstado from "./pages/Operaciones/index";
import EstadoMuelle from "./pages/Muelle/MuelleEstado";
import FrontRoutes from "./pages/Admin/RutasWeb";
import Users from "./pages/Admin/Usuarios";
import UsuarioDetalle from "./pages/Admin/Usuarios/details";
import Maquinas from "./pages/Mantenimiento/Maquinas";
import CreateChecklist from "./pages/Mantenimiento/Checklists/CrearChecklist";
import ChecklistsDetalles from "./pages/Mantenimiento/Checklists/CrearChecklist/details";
import Maquinistas from "./pages/Mantenimiento/Maquinistas";
import Checklists from "./pages/Mantenimiento/Checklists/EnviarChecklist";
import ChecklistMaquina from "./pages/Mantenimiento/Checklists/EnviarChecklist/details";
import MaquinaChecklists from "./pages/Mantenimiento/Maquinas/details";
import MaquinistaChecklists from "./pages/Mantenimiento/Maquinistas/details";
import CompareChecklists from "./pages/Mantenimiento/Checklists/CompareChecklists";
import KmViajesLargaDist from "./pages/Camiones/EntidadesSecundarias/KmLargaDist";
import TarifasNegociadas from "./pages/Camiones/EntidadesSecundarias/TarifasNegociadas";
import ResumenesOperativos from "./pages/Camiones/EntidadesSecundarias/ResumenesOperativos";
import TableVehicleSAF from "./pages/Camiones/Operativos/components/details/TableVehicleSAF";
import Correos from "./pages/Admin/Correos";
import ChecklistsPorDia from "./pages/Mantenimiento/ChecklistsPorDia";
import ChklAbiertas from "./pages/Mantenimiento/ChecklistsAbiertas";
import ChklAbiertasAll from "./pages/Mantenimiento/ChecklistsAbiertas/Checklists All";
import MaqDetalleChecklist from "./pages/Mantenimiento/MaqDetalleChkl";
import MaquinasChklCambiarHoras from "./pages/Mantenimiento/ChecklistsHoras";
import ChklCambiarHoras from "./pages/Mantenimiento/ChecklistsHoras/details";
//components
import NavBarNotAuth from "./components/NavBars/NavBarNotAuth";
import NavBarCustom from "./components/NavBars/NavBarCustom";
import LoginForm from "./pages/Login/LoginForm";
import ForgotPassword from "./pages/Login/ForgotPassword";
import CambiarPass from "./pages/Login/CambiarPass";
//AuthContext
import { useAuth } from "./pages/Login/utilities/AuthContext";
//Comp Map for auth users pages
const COMPONENT_MAP = {
  Vehiculos: <Vehiculos />,
  Choferes: <Choferes />,
  Cubiertas: <Cubiertas />,
  Operativos: <Operativos />,
  OperativosViajes: <OperativosViajes />,
  VehiculosPersonal: <VehiculosPersonal />,
  CubiertasPersonal: <CubiertasPersonal />,
  Combustible: <Combustible />,
  VehiculosDetalles: <VehiculosDetalles />,
  ChoferesDetalles: <ChoferesDetalles />,
  VehiculosPersonalDetalles: <VehiculosPersonalDetalles />,
  OperacionesEstado: <OperacionesEstado />,
  EstadoMuelle: <EstadoMuelle />,
  FrontRoutes: <FrontRoutes />,
  Users: <Users />,
  UsuarioDetalle: <UsuarioDetalle />,
  Maquinas: <Maquinas />,
  MaquinaChecklists: <MaquinaChecklists />,
  Maquinistas: <Maquinistas />,
  MaquinistaChecklists: <MaquinistaChecklists />,
  CreateChecklist: <CreateChecklist />,
  ChecklistsDetalles: <ChecklistsDetalles />,
  Checklists: <Checklists />,
  ChecklistMaquina: <ChecklistMaquina />,
  CompareChecklists: <CompareChecklists />,
  KmViajesLargaDist: <KmViajesLargaDist />,
  TarifasNegociadas: <TarifasNegociadas />,
  ResumenesOperativos: <ResumenesOperativos />,
  TableVehicleSAF: <TableVehicleSAF />,
  correos: <Correos />,
  checklistsPorDia: <ChecklistsPorDia />,
  checklistsAbiertas: <ChklAbiertas />,
  MaqDetalleChecklist: <MaqDetalleChecklist />,
  chkl_cambiar_horas_maquinas: <MaquinasChklCambiarHoras />,
  chkl_cambiar_horas: <ChklCambiarHoras />,
  chkl_abiertas_all: <ChklAbiertasAll />,
};
function App() {
  const { auth, routes } = useAuth();
  const getUserRoutes = () => {
    let content = [];
    for (var key in routes[0]) {
      content.push(
        routes[0][key].map((r) => {
          return (
            <Route
              path={r.path}
              key={r.id}
              element={COMPONENT_MAP[r.element]}
            />
          );
        })
      );
    }
    return content;
  };
  return (
    <>
      {!auth ? (
        <div>
          <NavBarNotAuth />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/new-password/:resettoken" element={<CambiarPass />} />
          </Routes>
        </div>
      ) : (
        <div>
          <NavBarCustom />
          <Routes>
            <Route path="/" element={<HomePage />} />
            {routes ? getUserRoutes() : null}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      )}
    </>
    /*<div>
      <button onClick={handleTest}>Probar algo</button>
  </div>*/
    /*El btn de "Probar algo" es una ayuda que tengo */
  );
}

export default App;
