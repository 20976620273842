import { useState } from "react";
export const useAlertModal = () => {
  const [alertMsj, setAlertMsj] = useState(null);
  const [alertState, setAlertState] = useState(null);
  const [alertType, setAlertType] = useState(null);

  const handleAlertSuccess = (api_response) => {
    setAlertMsj(api_response.data.msj);
    setAlertType("success");
    setAlertState(1);
  };
  const handleAlertError = (msj) => {
    setAlertMsj(msj);
    setAlertType("danger");
    setAlertState(2);
  };
  const handleAlertWarning = (msj) => {
    setAlertMsj(msj);
    setAlertType("warning");
    setAlertState(2);
  };
  return {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
    handleAlertWarning,
  };
};
