import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const ExportExcel = ({ excelData }) => {
  const [fileName, setFileName] = useState("");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    const userInput = window.prompt("Guardar tabla como:", "");
    if (userInput !== null) {
      setFileName(userInput);
    }
  };

  useEffect(() => {
    if (fileName !== "" && excelData && fileType) {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }, [fileName, fileType]);

  return (
    <>
      <Button onClick={exportToExcel} className="btn-success btn-sm">
        Exportar Excel
      </Button>
    </>
  );
};

export default ExportExcel;
