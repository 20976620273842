export function filterFieldProps(columns, data) {
  //Obtenemos los valores de field
  const fieldValues = columns.map((col) => col.field);
  //Filtrar y crear nuevo objeto sin las props que no coinsidan con las field
  const filteredData = data.map((item) => {
    const newItem = {};
    for (const property of fieldValues) {
      if (item.hasOwnProperty(property)) {
        newItem[property] = item[property];
      }
    }
    return newItem;
  });
  return filteredData;
}
/*
  Esta funcion permite a partir de los tipicos arreglos columns y data de las tablas
  generar un arreglo donde se eliminan de data todas aquellas props definidas en las
  props field del arreglo columns.
  Ejemplo: 
  const data = [{id: 1, name: "Enzo", kilometros: 250}, {id: 2, name: "Juan", kilometros: 100}];
  const columns = [{field: "id", header: "ID" }, {field: "name", header: "Nombre"}];
  Nuevo arreglo generado a partir de esta funcion:
  const newArr = [{id: 1, name: "Enzo"}, {id: 2, name: "Juan"}];
  Como en la constante columns no existe ningun objeto con prop field: "kilometros", el nuevo arreglo
  replicara la forma de data pero sacando de todos sus objetos la prop kilometros.
*/
