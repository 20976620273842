import { useState } from 'react'
export const useModal = () => {
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('error')
    const [msjModal, setMsjModal] = useState(null)
    const handleClose = () => setShowModal(false);
    /*
        Esta func recibe una instancia de la clase error
        resultado de la llamada a la API, luego se busca 
        la prop msj que siempre retorna la API y se lo setea como
        texto de error
    */
    const handleError = (msj) => {
        setMsjModal(msj)
        setModalType('error')
        setShowModal(true)
    }
    /*
        Esta func se usa cuando se ejecuta con exito
        una llamada a la api, recibe la respuesta de la API
        y busca en ella la prop msj que siempre retorna la API
        y la setea como mensaje en el modal
    */
    const handleSucces = (api_response) =>{
        setMsjModal(api_response.data.msj)
        setModalType('success')
        setShowModal(true)
    }
    return ({
        showModal,
        setShowModal,
        modalType,
        setModalType,
        msjModal,
        setMsjModal,
        handleClose,
        handleError,
        handleSucces
    })
}
