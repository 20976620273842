import { Modal, Button } from "react-bootstrap";
/*
    Este modal recibe un componente como hijo y
    lo renderiza dentro de su body
*/
export default function PlainModal({
  show,
  handleClose,
  children,
  size,
  noFooter,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size={size === "lg" ? "lg" : null}
      className="custom-modal"
    >
      <Modal.Body className="modal-scroll">{children}</Modal.Body>
      {noFooter ? null : (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
