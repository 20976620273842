import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../api/axiosConfig";
import { urlGetOperaciones } from "../../../api/URLs/operaciones";
//HOOKS
import { useModal } from "../../../hooks/useModal";
//COMPONENTS
//--Modal
import ModalAlert from "../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../components/Tools/Tables/Table/Table";

const TableEstadoOperaciones = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [operaciones, setOperaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  //GET
  const getOperaciones = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetOperaciones);
      setOperaciones(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    { header: "Emp.", field: "nomempre" },
    //{ header: "Tot.", field: "total" },
    { header: "Sól.", field: "solido" },
    //{ header: "Líq.", field: "liquido" },
    { header: "S.ord", field: "psino" },
    { header: "C.ord", field: "pcono" },
    { header: "Ope", field: "ope" },
    { header: "Fin", field: "fin" },
  ];
  useEffect(() => {
    getOperaciones();
  }, []);
  return (
    <div>
      <Table data={operaciones} columns={columns} loading={loading} />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableEstadoOperaciones;
