import { useState, useEffect } from "react";
import { axiosWithAuth } from "../../../../api/axiosConfig";
import "./Table.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomBtn from "../../Basics/CustomBtn";
import CenterSpinner from "../../Basics/Spinner";
import { cellFormater } from "../../../../functions/cellFormater";
import { exportPdf } from "../../../../functions/pdf/exportPdf";

const Table = ({
  data,
  columns,
  sortField,
  keyProp,
  title,
  buttons,
  loading,
  textFilter,
  scrollSize,
  url,
  reFetch,
  pdf,
  ...props
}) => {
  //PDF PROPS
  const pdfSave = pdf && pdf.pdfSave ? pdf.pdfSave : "Table";
  const pdfTitle = pdf && pdf.pdfTitle ? pdf.pdfTitle : "";
  const pdfOrientation = pdf && pdf.pdfHorizontal ? "l" : "p";
  const pdfCompact = pdf && pdf.pdfCompact ? "compact" : "";
  //FETCH URL
  const [dataFetch, setDataFetch] = useState([]);
  const fetchData = async () => {
    const api_responses = await axiosWithAuth.get(url);
    setDataFetch(api_responses.data);
  };

  //FILTER
  const [search, setSearch] = useState("");
  const filteredData = (url ? dataFetch : data).filter((d) => {
    const lowerCasedValue = search.toLowerCase();
    return Object.keys(d).some((key) =>
      d[key]?.toString()?.toLowerCase()?.includes(lowerCasedValue)
    );
  });

  const handleScroll = (size) => {
    switch (size) {
      case "sm":
        return "small";
      case "md":
        return "medium";
      case "lg":
        return "large";
      case "xl":
        return "large-xl";
      default:
        return "auto";
    }
  };
  useEffect(() => {
    if (url) {
      fetchData();
    }
  }, [reFetch]);
  return (
    <div className="mb-2">
      {!loading ? (
        <>
          <div className="d-sm-flex justify-content-between align-items-end mb-0 pb-1 header-details-box">
            <p className="fw-bold">{title}</p>
            <div className="d-flex">
              {pdf ? (
                <i
                  className="bi bi-filetype-pdf fs-4 me-1 text-danger pointer"
                  onClick={() =>
                    exportPdf(
                      columns,
                      filteredData,
                      pdfSave,
                      pdfTitle,
                      pdfOrientation,
                      pdfCompact,
                    )
                  }
                />
              ) : null}
              {buttons
                ? buttons.map((b, index) => (
                    <div key={index}>
                      <CustomBtn
                        onClick={() =>
                          b.click(b.noData ? null : url ? dataFetch : data)
                        }
                        type={b.type}
                        btnType={b.styles}
                        icon={b.icon}
                        text={b.text}
                        disabled={b.disabled}
                      />
                    </div>
                  ))
                : null}
              {textFilter ? (
                <div>
                  <input
                    className={`table-filter ${!buttons ? "py-1" : "ms-1"}`}
                    type="text"
                    id="filter"
                    placeholder="Buscar"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <DataTable
            value={filteredData}
            sortField={sortField ? sortField : null}
            tableStyle={{ minWidth: "100%" }}
            className={`table-primereact custom-striped table-scroll-box-${handleScroll(
              scrollSize
            )}`}
            removableSort
            {...props}
          >
            {columns.map((col, index) => (
              <Column
                key={keyProp ? keyProp : index}
                field={col.field}
                header={col.header}
                body={
                  col.formater
                    ? (t) => cellFormater(t[col.field], col.formater)
                    : col.body
                }
                sortable={col.sortable ? true : null}
              />
            ))}
          </DataTable>
        </>
      ) : (
        <CenterSpinner />
      )}
    </div>
  );
};

export default Table;
