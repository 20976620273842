import { useField } from "formik";

const CustomCheckbox = ({ text, children, size, loading, ...props }) => {
  const [field] = useField({ ...props, type: "checkbox" });
  //console.log(field)
  return (
    <div className="my-2 text-nowrap">
      <input
        className={size === "lg" ? "input-size-lg" : "me-2"}
        type="checkbox"
        disabled={loading ? true : false}
        {...field}
        {...props}
      />
      <span>{children ? children : text}</span>
    </div>
  );
};

export default CustomCheckbox;
