//RUTAS VEHICULOS
//--fam
export const urlGetVehicles = "/api/vehicles";
export const urlCreateVehicles = "/api/vehicles";
export const urlEditVehicles = "/api/vehicles";
//--tipos de vehiculos
export const urlGetTrucks = "/api/vehicles/by-type/1";
export const urlGetTrailers = "/api/vehicles/by-type/2";
//--vincular/desvincular cubiertas
export const urlLinkVehiclesTyres = "/api/vehicles-tyres";
//FORM VEHICLE TYRES
//--cubiertas no asignadas
export const urlGetNotAssignedTyres = "/api/tyre-not-asigned";
//--tipos de vehiculos
export const urlGetVehicleTypes = "/api/tipos/vehicles";
