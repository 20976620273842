import { Button } from "react-bootstrap";

const ButtonSubmit = ({
  prop,
  isSubmitting,
  onClick,
  loading,
  variant,
  text,
  className,
  type,
  modalMinSize,
}) => {
  const variantFinal = variant ? variant : prop ? "success" : "primary";
  const buttonText = text ? text : prop ? "Modificar" : "Crear";

  return (
    <Button
      variant={variantFinal}
      className={`w-100 ${className ? className : ""} mt-3 modal-min-size-${modalMinSize ? modalMinSize : "auto"}`}
      type={type ? type : "submit"}
      disabled={isSubmitting}
      onClick={onClick}
    >
      {loading ? (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="sr-only px-2">Cargando...</span>
        </>
      ) : (
        buttonText
      )}
    </Button>
  );
};

export default ButtonSubmit;
