import "./BoxContainer.css";

const BoxContainer = ({ children, minSize, maxSize }) => {
  const handleSize = (size) => {
    switch (size) {
      case "sm":
        return "small";
      case "md":
        return "medium";
      case "lg":
        return "large";
      case "xl":
        return "large-xl";
      default:
        return "auto";
    }
  };
  return (
    <div
      className={`details-component-box p-sm-4 p-2 mb-2 size-box-${handleSize(
        minSize
      )} size-box-max-${handleSize(maxSize)}`}
    >
      {children}
    </div>
  );
};

export default BoxContainer;
