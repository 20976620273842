import { Button, Modal } from "react-bootstrap";

const TYPES = ["success", "error"];

const ModalAlert = ({ show, handleClose, type, msj }) => {
  const modalType = TYPES.includes(type) ? type : "error";
  //Styles

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="bg-modal-background mt-3"
      >
        <Modal.Header className={modalType === 'success' ?'bg-success' :'bg-danger'}>
          <Modal.Title className="text-white">{modalType === "success" ? "Ejecutado con exito" : "Error"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-dark">
          {msj ? msj : modalType === 'success' ?'Todo ha salido bien' :'Algo ha salido mal'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalAlert;