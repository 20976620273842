import { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

function CustomDropdown({
  options,
  onSelect,
  displayProperty,
  btnName,
  defaultOption,
  keyProperty,
  label,
  loading,
  size,
  noFilter,
}) {
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function handleSelect(option) {
    setIsOpen(false);
    setSelectedOption(option);
    onSelect(option);
  }

  const filteredOptions = options.filter((option) =>
    option[displayProperty].toLowerCase().includes(searchText.toLowerCase())
  );

  const menuStyle = {
    maxHeight: "200px",
    overflowY: "auto",
  };

  return (
    <>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <div className="dropdown" ref={dropdownRef}>
        <button
          className={`btn ${size === "lg" ? "btn-lg" : null} border dropdown-toggle w-100 text-start ${!selectedOption ? "text-grey" : null}`}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          disabled={loading ? true : false}
        >
          {selectedOption ? selectedOption[displayProperty] : btnName}
        </button>
        {isOpen && (
          <div className="dropdown-menu show w-100 p-0" style={menuStyle}>
            {!noFilter ? (
              <input
                className="form-control form-control-sm border-0 focus-none"
                type="text"
                placeholder="Buscar..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            ) : null}
            <div className="list-group list-group-flush">
              {filteredOptions.map((option) => (
                <button
                  key={keyProperty ? option[keyProperty] : option.id}
                  type="button"
                  className={`list-group-item list-group-item-action ${size === "lg" ? "p-2" : "p-1"}`}
                  onClick={() => handleSelect(option)}
                >
                  {option[displayProperty]}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CustomDropdown;
