import React, { useState } from "react";
import "./navCss.css";

import Divider from "../../Tools/Basics/Divider";

const NavTabs = ({ sections }) => {
  const [activeTab, setActiveTab] = useState(sections[0].name);

  const handleTabClick = (sectionName) => {
    setActiveTab(sectionName);
  };

  const renderActiveSection = () => {
    const activeComponent = sections.find(
      (section) => section.name === activeTab
    );
    return activeComponent ? activeComponent.component : null;
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <ul className="navbar-nav d-flex flex-row flex-wrap justify-content-start selected-tab custom-space">
          {sections.map((section) => (
            <li
              key={section.name}
              className={`${
                activeTab === section.name ? "active" : ""
              } nav-item bg-light rounded py-2 px-2 text-secondary`}
              onClick={() => handleTabClick(section.name)}
            >
              <span className="fs-6">{section.name}</span>
            </li>
          ))}
        </ul>
      </nav>
      <Divider />
      {renderActiveSection()}
    </div>
  );
};

export default NavTabs;
