import { useState, useEffect } from "react";
// API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetMaquinistas,
  urlGetChklSinCerrar,
} from "../../../../api/URLs/mantenimiento";
// COMPONENTS
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
// CSS
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "./index.css";
//Functions
import { dateSQLformat } from "../../../../functions/dateSQLformat";
//Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//Excel
import ExportExcel from "../../../../functions/ExportExcel";
//ROUTER
import { useNavigate } from "react-router-dom";

const ChklAbiertasAll = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataMaquinistas, setDataMaquinistas] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("error");
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const handleCloseModal = () => setShowModal(false);

  // Cargar maquinistas al inicio
  useEffect(() => {
    const fetchMaquinistas = async () => {
      try {
        const response = await axiosWithAuth.get(urlGetMaquinistas);
        setDataMaquinistas(response.data);
      } catch (error) {
        setModalMessage("Error al obtener maquinistas");
        setModalType("error");
        setShowModal(true); // Mostrar modal de error
      }
    };

    fetchMaquinistas();
  }, []);

  // Obtener checklists abiertas
  const getChkl = async () => {
    try {
      setLoading(true);
      const apiResponses = await Promise.all(
        dataMaquinistas.map((maquinista) =>
          axiosWithAuth.post(urlGetChklSinCerrar, {
            psw_maquinista: maquinista.psw_checklist,
          })
        )
      );
      const combinedData = apiResponses.flatMap((response) => response.data);
      setData(combinedData);

      // Mostrar modal de éxito
      setModalMessage("Checklists cargadas con éxito");
      setModalType("success");
      setShowModal(true);
    } catch (error) {
      const auxError = error.response?.data?.msj || "Error del servidor";

      // Mostrar modal de error
      setModalMessage(auxError);
      setModalType("error");
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  // Filtrar datos por búsqueda
  const filteredData = data.filter((d) => {
    const lowerCasedValue = search.toLowerCase();
    return Object.keys(d).some((key) =>
      d[key]?.toString()?.toLowerCase()?.includes(lowerCasedValue)
    );
  });

  //templates

  const estadoBodyTemplate = (rowData) => {
    return rowData.estado ? "Cerrado" : "Abierto";
  };
  const obsBodyTemplate = (rowData) => {
    return rowData.otras_obs ? rowData.otras_obs : "N/A";
  };
  const fechaBodyTemplate = (rowData) => {
    const date = new Date(rowData.fec_restulado_chk);
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };
  const horaBodyTemplate = (rowData) => {
    const formattedTime = dateSQLformat(rowData.fec_restulado_chk, "time");
    return formattedTime;
  };
  const actions = (chkl) => {
    return (
      <a
        onClick={() =>
          navigate(
            `/mantenimiento/checklists/detalle/${chkl.id_resultado_chk}`,
            {
              state: {
                id_checklist: chkl.id_resultado_chk,
                id_maquina: chkl.id_maquina,
              },
            }
          )
        }
        className="link_table"
      >
        Detalles
      </a>
    );
  };

  return (
    <div className="custom-container">
      <div className="btns-header">
        <div>
          <button
            className="btn btn-success btn-sm me-2"
            onClick={getChkl}
            disabled={loading}
          >
            {loading ? "Cargando..." : "Cargar Checklists"}
          </button>
          <ExportExcel excelData={filteredData} />
        </div>
        <input
          className="table-filter"
          type="text"
          placeholder="Buscar..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className=" w-full">
        {!loading ? (
          <DataTable
            value={filteredData}
            className="table-abiertas custom-striped"
            removableSort
          >
            <Column
              field="id_resultado_chk"
              header="ID Chk"
              sortable
              headerClassName="header-grey"
            />
            <Column
              field="maquinista"
              header="Maquinista"
              sortable
              headerClassName="header-grey"
            />
            <Column
              field="fec_restulado_chk"
              header="Fecha"
              sortable
              body={fechaBodyTemplate}
              headerClassName="header-grey"
            />
            <Column
              field="hora"
              header="Hora"
              sortable
              body={horaBodyTemplate}
              headerClassName="header-grey"
            />
            <Column
              field="identificador_maq"
              header="ID Maq"
              headerClassName="header-grey"
            />
            <Column
              field="maq_marca"
              header="Marca"
              headerClassName="header-grey"
            />
            <Column
              field="maq_modelo"
              header="Modelo"
              headerClassName="header-grey"
            />
            <Column
              field="otras_obs"
              header="Obs"
              body={obsBodyTemplate}
              headerClassName="header-grey"
            />
            <Column
              field="estado"
              header="Estado"
              body={estadoBodyTemplate}
              headerClassName="header-grey"
            />
            <Column
              field="Detalles"
              header="Detalles"
              body={actions}
              headerClassName="header-grey"
            />
          </DataTable>
        ) : (
          <CenterSpinner className="spinner" />
        )}
      </div>

      {/* Modal de alerta */}
      <ModalAlert
        show={showModal}
        handleClose={handleCloseModal}
        type={modalType}
        msj={modalMessage}
      />
    </div>
  );
};

export default ChklAbiertasAll;
