//NavTabs
import NavTabs from "../../../components/NavBars/NavTabs/NavTabs";
//Components
import FormBrands from "../EntidadesSecundarias/Marcas/FormBrands";
import TableVehiculos from "./components/index/TableVehiculos";

const Vehiculos = () => {
  const sections = [
    { name: "Vehiculos", component: <TableVehiculos /> },
    { name: "Marcas", component: <FormBrands /> },
  ];
  return (
    <div className="custom-container">
      <h3>
        <b>Vehiculos Terminales y Servicios</b>
      </h3>
      <NavTabs sections={sections} />
    </div>
  );
};

export default Vehiculos;
