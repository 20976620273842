import * as Yup from "yup";

export const vehiclesSchema = Yup.object().shape({
  patente: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^([A-Z]{3}[0-9]{3}|[A-Z]{2}[0-9]{3}[A-Z]{2})$/,
      "Debe ingresar una patente valida (Mayusculas)"
    ),
  numChasis: Yup.string().required("Este campo es obligatorio"),
  numMovil: Yup.string().required("Este campo es obligatorio"),
});
