import { Spinner } from "react-bootstrap";

const CenterSpinner = () => {
  return (
    <div className="d-flex justify-content-center mb-3">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default CenterSpinner;