//Logo
import Vessel from '../../assets/logo.png'

export default function HomePage() {
  return (
    <div className='custom-container'>
      <h1 style={{
        textAlign: 'center',
        padding: '10px'
      }}>
        Logistica Terminales y Servicios
      </h1>
      <div>
        <img src={Vessel} className='logo'/>
      </div>
    </div>
  )
}
