import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlCreateOperativo,
  urlEditOperativo,
  urlGetOperativosTipos,
} from "../../../../api/URLs/operativos";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik } from "formik";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Basic
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";

const FormOperativos = ({ selectedOperativo, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  //INITIAL VALUES
  let newValues, updateValues;
  if (selectedOperativo) {
    updateValues = {
      nombre: selectedOperativo.nombre ? selectedOperativo.nombre : "",
    };
  } else {
    newValues = {
      nombre: "",
    };
  }
  const initialValues = selectedOperativo ? updateValues : newValues;
  //GET
  const getTypes = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetOperativosTipos);
      setTypes(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //POST
  const handleSubmit = async (values) => {
    try {
      setSendLoading(true);
      let api_response;
      if (!selectedOperativo) {
        api_response = await axiosWithAuth.post(urlCreateOperativo, {
          ...values,
          id_tipo: selectedType,
        });
      } else {
        api_response = await axiosWithAuth.put(urlEditOperativo, {
          ...values,
          id_tipo: selectedType,
          id: selectedOperativo.id,
        });
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };

  useEffect(() => {
    getTypes();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={selectedOperativo} text={"Operativo"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            {/* TIPO OPERATIVO */}
            <Form.Label>Tipo de Operativo</Form.Label>
            <CustomDropdown
              options={types}
              onSelect={(p) => setSelectedType(p.id)}
              displayProperty={"desc_tipo"}
              btnName={"Tipo de operativo"}
              defaultOption={
                selectedOperativo
                  ? { desc_tipo: selectedOperativo.desc_tipo }
                  : null
              }
            />
            {/* NOMBRE*/}
            <FormGroup>
              <Form.Label>Nombre de Operativo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresar nombre de operativo"
                name="nombre"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nombre}
                isInvalid={errors.nombre && touched.nombre && errors.nombre}
              />
            </FormGroup>
            <ButtonSubmit
              prop={selectedOperativo}
              isSubmitting={isSubmitting}
              loading={sendLoading}
              modalMinSize={"sm"}
            />
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormOperativos;
