import { cellFormater } from "../cellFormater";

export function filterPdfFormat(columns, data) {
  const dataPdfFormat = data.map((item) => {
    const newItem = { ...item };
    columns.forEach((column) => {
      if (column.pdfFormat && newItem.hasOwnProperty(column.field)) {
        newItem[column.field] = cellFormater(newItem[column.field], column.pdfFormat) ;
      }
    });
    return newItem;
  });
  return dataPdfFormat;
}
