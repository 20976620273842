const CustomBtn = ({ onClick, btnType, text, icon, disabled, type }) => {
  //Estilos
  const TYPE_STYLES = {
    edit_table: "success btn-sm py-0",
    delete_table: "danger btn-sm py-0 px-1 ms-1",
    details_table: "link btn-sm p-0 ms-1",
    link_table: "link btn-sm py-0",
    link_success_table:
      "btn btn-sm text-success py-0 border rounded border-success",
    link_danger_table:
      "btn text-danger btn-sm py-0 border rounded border-danger",
    plus_table_header: "success btn-sm",
    minus_table_header: "danger btn-sm",
    edit_table_header: "success btn-sm",
    download_table_header: "success btn-sm",
  };
  const typeStyles = TYPE_STYLES[type]
    ? TYPE_STYLES[type]
    : btnType
    ? btnType
    : null;
  //Iconos
  const TYPE_ICON = {
    edit_table: "bi bi-pencil-square",
    delete_table: "bi bi-trash3",
    plus_table_header: "bi bi-plus-lg",
    minus_table_header: "bi bi-dash",
    edit_table_header: "bi bi-pencil-square",
    download_table_header: "bi bi-download",
  };
  const typeIcon = TYPE_ICON[type] ? TYPE_ICON[type] : icon ? icon : null;
  //Texto
  const TYPE_TEXT = {
    details_table: "Ver Detalles",
  };
  const typeText = TYPE_TEXT[type] ? TYPE_TEXT[type] : text ? text : null;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`btn btn-${typeStyles} text-nowrap ${btnType}`}
    >
      <i className={typeIcon} />
      {typeText}
    </button>
  );
};

export default CustomBtn;
