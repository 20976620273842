import { filterFieldProps } from "./filterFieldProps";
import { filterPdfFormat } from "./filterPdfFormat";
//js-PDF
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

const cellPadding = { top: 2, bottom: 2, right: 1, left: 1 };
const cellPaddingCompact = { top: 1.5, bottom: 1.5, right: 0.3, left: 0.3 };

export function exportPdf(columns, data, save, title, orientation, compact) {
  //Obtenemos los header de columnas con field
  const headers = columns
  .map((col) => col.field && col.header)
  .filter((header) => header !== undefined);
  //Filtramos data para mostrar solo las props existentes en la tabla
  const fieldProps = filterFieldProps(columns, data);
  //Formateamos las props que sean necesarias
  const pdfFormat = filterPdfFormat(columns, fieldProps);
  //Creamos el formato necesario para el body de autotable
  const formatBodyPdf = pdfFormat.map((b) => Object.values(b));
  const doc = new jsPDF({
    orientation: orientation,
  });
  doc.setFontSize(12);
  doc.text(title, 14, 10);
  autoTable(doc, {
    head: [headers],
    body: formatBodyPdf,
    styles: { cellPadding: compact === "compact" ? cellPaddingCompact : cellPadding },
    headStyles: { fillColor: [100, 156, 85], fontSize: 9 },
    bodyStyles: { fontSize: 8 },
  });
  doc.save(save);
}
