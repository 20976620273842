const CustomAlertMsj = ({ alertType, alertMsj, className }) => {
  return (
    <div
      className={`modal-content border border-${alertType} border-1 text-center ${
        className ? className : "py-2"
      }`}
    >
      <div className="d-flex justify-content-center align-items-center">
        <div className="fw-semibold">{alertMsj}</div>
        <div>
          <i
            className={`bi fs-3 mx-2 ${
              alertType === "success"
                ? "bi-check-circle text-success"
                : alertType === "warning"
                ? "bi-exclamation-diamond text-warning"
                : "bi-exclamation-diamond text-danger"
            }`}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default CustomAlertMsj;
